import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';

// components
import SamplingEventInputs from './SamplingEventInputs/SamplingEventInputs';

// mui
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

// utils
import "../../utils/styles/cocStyle.css"
import dateHandlers from "../../utils/functions/dateHandlers";

const SamplesData = ({ page, testingList, renderSamples, headers, samplingEventMode, data, samplingEventInputs, setSamplingEventInputs, userInput, setUserData, bottleList, samplingMode, showBottles }) => {

    const handleInput = (e, key, sID) => {
        const newUserInput = cloneDeep(userInput)
        const findSample = newUserInput.samples.find(s => s.id === sID)
        findSample[key] = e.target.value
        setUserData(newUserInput)
    }

    const sampleData = data.forms.sections.find(f => f.type === 'sample')

    return (
        <>

            {renderSamples.map((sample, i) => {

                let bgColor = "white";

                if (sample.samplingDuplicate) {
                    bgColor = "#C8E8FF";

                    switch (sample.samplingDuplicate) {
                        case "Primary":
                            bgColor = "white"
                            break;
                        case "Duplicate":
                            bgColor = "#BFFFB5"
                            break;
                        default:
                            break;
                    }
                }

                const uniques = sample.activeTests?.filter(at => at.individual);
                const modeled = []
                uniques?.forEach(at => {
                    const findGroup = modeled.find(g => g.individualName === at.individualName)
                    if (findGroup) {
                        findGroup.activeTests.push(at)
                    } else {
                        modeled.push({
                            individualName: at.individualName,
                            activeTests: [at]
                        })
                    }
                })

                modeled.forEach(g => g.activeTests = JSON.stringify(g.activeTests?.map(at => at.LIMS_ID).sort()))

                return (
                    <TableRow key={sample.id + "-" + sample.name} className="cocRow" >


                        {/* sampling event inputs here */}
                        {samplingEventMode ?
                            <SamplingEventInputs data={data} sampleID={sample.id} samplingEventInputs={samplingEventInputs} setSamplingEventInputs={setSamplingEventInputs} />
                            : null}


                        {/* MUST HANDLE UNITS HERE */}
                        {headers.map(h => {

                            const generalSampleForm = sampleData.sub_sections.find(ss => ss.sectionKey === 'general')
                            const inputReference = generalSampleForm.inputs.find(input => input.input_key === h)
                            const samplingSample = userInput.samplingEventInputs?.length > 0 ? userInput.samplingEventInputs?.find(s => s.id === sample.id) : false

                            const totalMass =
                                samplingSample && samplingSample.increments ?
                                    samplingSample.increments.map(inc => inc.sampleMass ? inc.sampleMass * 1 : 0).reduce((a, b) => a + b, 0).toFixed(2)
                                    : false

                            const unitKey = h + "Unit"
                            const unitVal = data.references?.units?.find(item => item.LIMS_ID === sample[unitKey])?.DISPLAYNAME

                            // Split key into words by parsing as camelCase to check if it is labeled as date and/or time.
                            const splitKeys = new Set(h.replace(/([A-Z])/g, ' $1').split(' ').map(x => x.toLowerCase()));
                            const isDate = splitKeys.has('date') || splitKeys.has('datetime');
                            const isTime = splitKeys.has('time') || splitKeys.has('datetime');

                            const isDateTime = (sample[h]?.isDayjsObject || isTime || isDate)

                            // new sampling event
                            const samplingForm = data?.forms?.sections?.find(s => s.type === 'samplingEvent' && s.samplingEventKey === userInput.submissionType.split(',')[1])
                            const fullInputs = samplingForm?.sub_sections.map(ss => ss.inputs).flat()
                            const isSamplingEventInput = fullInputs?.find(input => input.label === h)?.input_key

                            return (
                                <td key={h + "-" + sample.id} className="cocCell" align="center" style={{ backgroundColor: bgColor }}>
                                    {samplingEventMode && inputReference?.samplingInput
                                        ?
                                        <>

                                            {inputReference.type === "select" ?
                                                <Select
                                                    value={sample ? sample[h] ?? " " : " "}
                                                    onChange={(e) => { handleInput(e, h, sample.id) }}
                                                    style={{ fontSize: "0.7em", minWidth: "125px" }}
                                                >
                                                    {inputReference.selection.value.map((item, i) => {
                                                        return <MenuItem key={h + "-input-" + i + "-" + sample.id + "-" + item} value={item}>{item}</MenuItem>
                                                    })}

                                                </Select>
                                                : null}

                                            {inputReference.type === "input" ?
                                                <TextField
                                                    size="small"
                                                    style={{ fontSize: "0.7em", minWidth: "125px" }}
                                                    value={sample ? sample[h] ?? " " : " "}
                                                    onChange={(e) => { handleInput(e, h, sample.id) }}
                                                />
                                                : null}

                                        </>
                                        :

                                        <>


                                            {/* Index Column */}
                                            {h === "id" ? <>{((i + 1) + (page * 10))}</> : null}

                                            {/* Actual Column */}
                                            {h !== "id" && h !== 'matrix' && !isDateTime && !(h.toString().toLowerCase().includes('thresholds') || h.toString().toLowerCase().includes('mrl')) ? sample[h] : null}

                                            {/* Date/Time */}
                                            {
                                                (() => {
                                                    const value = sample[h] || samplingSample?.[h];
                                                    if (value) {
                                                        if (isDate && isTime)
                                                            return dateHandlers.localizedStringToHumanReadable(value);
                                                        else if (isDate)
                                                            return dayjs(value)?.format('MM/DD/YYYY');
                                                        else if (isTime)
                                                            return dayjs(value)?.format('LT');
                                                    }
                                                })()
                                            }

                                            {/* Matrix */}
                                            {h === 'matrix' ? data.references?.materials?.find(m => m.LIMS_ID === sample[h])?.DISPLAYNAME : null}

                                            {/* Thresholds  (multiselect at first but may be more than that later) */}
                                            {h.toString().toLowerCase().includes('thresholds') || h.toString().toLowerCase().includes('mrl') ?
                                                <>
                                                    {sample[h].indexOf('isArray') > -1 ?
                                                        sample[h].split(':isArray:').filter(ai => ai !== '').map((arrayItem, index) => {
                                                            return data.references?.thresholds?.find(t => t.LIMS_ID === arrayItem)?.DISPLAYNAME + (index < sample[h].split(':isArray:').filter(ai => ai !== '').length - 1 ? ', ' : '') || sample[h]
                                                        })
                                                        :
                                                        data.references?.thresholds?.find(t => t.LIMS_ID === sample[h])?.DISPLAYNAME || sample[h]
                                                    }
                                                </>
                                                : null}

                                            {/* Units, if necessary */}
                                            {sample[unitKey] && sample[h] ? " " + (unitVal || sample[unitKey]) : null}

                                            {/* Sampling Event */}
                                            {!sample[h] && samplingSample && samplingSample?.[h] && !isDateTime ? samplingSample[h] : null}

                                            {/* Sampling Event */}
                                            {h === "Total Mass Taken" && totalMass ? totalMass + " g" : null}

                                            {isSamplingEventInput ? samplingSample?.[isSamplingEventInput] : null}

                                            {/* An unused Cell */}
                                            {!samplingSample?.[h] && !isSamplingEventInput && !sample[h] && (!samplingSample || !samplingSample[h]) && h !== "id" && !!(h !== "Total Mass Taken") ? "N/A" : null}


                                        </>

                                    }
                                </td>
                            )
                        })}


                        {/* map testing here */}
                        {
                            testingList.map((m, i) => {

                                let presentCheck = !!sample.activeTests?.find(t => t.LIMS_ID === m.LIMS_ID)

                                if (m.individualName) {

                                    const checkVal = JSON.stringify(m.activeTests?.map(at => at.LIMS_ID).sort())
                                    presentCheck = !!modeled.find(c => c.individualName === m.individualName && c.activeTests === checkVal)

                                }
                                return <td style={{ backgroundColor: bgColor }} key={"test-check-" + (m.LIMS_ID || ('individuals' + i))} className="cocCell" align="center">{presentCheck ? <CheckIcon className="cocCheck" /> : null}</td>
                            })
                        }

                        {data.forms?.bottlesMenu && (!samplingMode || showBottles) ?
                            <>
                                {bottleList?.map((b, i) => {
                                    const presentCheckB = sample.activeBottles?.find(t => t.BOTTLE_LIMS_ID === b.BOTTLE_LIMS_ID)
                                    return (
                                        <td key={'bottle-sample-' + i + sample.id} style={{ fontSize: '0.01vw !important' }} className={i === 0 ? 'cocCellBottleSampleFirst' : 'cocCellBottleSample'}>
                                            {presentCheckB?.lotNumber || ''}
                                        </td>
                                    )
                                })}
                            </>
                            : null}
                    </TableRow>
                )
            })}

        </>

    );
}

export default SamplesData;
