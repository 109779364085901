import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
dayjs.extend(utc);
dayjs.extend(timezone);

const dateHandlers = {
    standardDate: function (received) {

        const inputDate = new Date(received)
        // takes fs date/time format to readable format
        let date, month, year;

        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();

        date = date
            .toString()
            .padStart(2, '0');

        month = month
            .toString()
            .padStart(2, '0');

        return `${month}/${date}/${year}`;
    },
    standardTime: function (received) {

        var today = new Date(received);
        var hh = today.getHours();
        var mm = today.getMinutes();

        return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}`;
    },
    /**
     * Converts stored localized string to DayJS date. Note that date will be converted to the timezone of the current machine.
     * @param {*} x Localized datetime string as produced by dayJsToLocalizedString.
     * @returns DayJS date.
     */
    localizedStringToDayJs: function (x) {
        return dayjs(x.split(';')[0]);
    },
    /**
     * Converts DayJS date to localized string containing UTC offset and local time zone.
     * @param {*} x DayJS date.
     * @returns Localized string containing date in ISO format with UTC offset, followed by semicolon and timezone
     */
    dayJsToLocalizedString: function (x) {
        const tz = dayjs.tz.guess();
        return `${x.tz(tz).format()};${tz}`;
    },
    /**
     * Converts stored localized string to human readable date and time.
     * @param {*} x Localized datetime string as produced by dayJsToLocalizedString method.
     * @param {boolean} alwaysIncludeTimezone If true, shortened time zone will always be included. Otherwise it will only be included if stored time zone differes from that of current machine.
     * @returns Human readable representation of date and time, including shortened time zone (e.g. CST) if applicable.
     */
    localizedStringToHumanReadable: function (x, alwaysIncludeTimezone) {

        // Get timezone of current machine.
        const currentTz = dayjs.tz.guess();

        // Separate date and timezone from value.
        const parts = x.split(';');
        const dtstr = parts[0];
        const storedTz = parts[1];

        // Determine output format (show timezone if different from current or explicitly instructed).
        const fmtBase = 'M/D/YYYY LT';
        const fmt = (storedTz !== currentTz) || alwaysIncludeTimezone
            ? `${fmtBase} z`
            : fmtBase;

        // Parse date, interpret using stored timezone, and format.
        return dayjs(dtstr).tz(storedTz).format(fmt);
    },
    /**
     * Checks if provided value is valid localized datetime string as produced by dayJsToLocalizedString method.
     * @param {*} x Date string to validate, as produced by dayJsToLocalizedString method.
     * @returns true if input is in the correct format and contains a valid date, otherwise false.
     */
    isValidLocalizedString: function (x) {
        if (x != null && typeof x === 'string') {
            const split = x.split(';');
            if (split.length === 2) {
                return dayjs(split[0]).isValid();
            }
        }
        return false;
    },
}


export default dateHandlers;

