import * as React from 'react';

// mui
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// utils
import checkComplexRequired from '../../../utils/functions/checkComplexRequired';
import dateHandlers from '../../../utils/functions/dateHandlers';

/**
 * UI component for selecting date and time which produces result containing locale info.
 * @param {*} param0 
 * @returns 
 */
const UserDateTimePicker = ({ value, refItem, handleInput, checkRequirements, sampleIndex, disabled }) => {

    let useInput = refItem && refItem[value.input_key] ? refItem[value.input_key] : ''

    const requiredCheck = checkComplexRequired(value.required, useInput, sampleIndex)

    const renderError = checkRequirements && requiredCheck && useInput === ''

    const middleInput = (key, val) => {
        if (!val) {
            handleInput(false, key, false, 'delete')
        } else {
            // Convert to localized date string for storage
            handleInput(false, key, dateHandlers.dayJsToLocalizedString(val))
        }
    }

    return (
        <Grid item xs={value.xs} md={value.md} id={value.input_key + "DateTimePickerField"}   >

            <DateTimePicker
                label={<span>{value.label}<span className='required-asterisk'>{requiredCheck ? " *" : ""}</span></span>}
                disableFuture={value.disableFuture || false}
                disabled={disabled}
                onChange={(e) => middleInput(value.input_key, e)}
                sx={{ width: '100%', padding: '0px' }}
                value={useInput ? dateHandlers.localizedStringToDayJs(useInput) : null}
                slotProps={{ textField: { size: 'small', InputLabelProps: { shrink: true } } }}
            />
            {renderError}
            {renderError ? <FormHelperText style={{ color: "red" }}>Required Entry</FormHelperText> : null}

        </Grid>
    );
}

export default UserDateTimePicker;
